import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { config } from '../../config';

export const contentService = {
  getFaqs,
  getRecommendationDefinitionsByRoomName,
  getLearningModules,
  getLearningModuleById,
  getClientConsiderations,
  getClientTenantTypes,
  getClientHousingTypes,
  getClientLivesWithTypes,
};

async function getFaqs() {
  const contentful = require('contentful');
  var returnList = [];

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });

  var result = (
    await client.getEntries({ content_type: 'hflFaq', limit: 1000 })
  ).items;
  for (var i = 0; i < result.length; i++) {
    var faq = {
      question: result[i].fields.question,
      answer: documentToHtmlString(result[i].fields.answer),
    };
    returnList.push(faq);
  }

  return returnList;
}
async function getLearningModules() {
  const contentful = require('contentful');
  var returnList = [];

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });
  var moduleDescription = await client
    .getContentType('hflModule')
    .then((contentType) => {
      // Access the description of the content type
      return contentType.description;
    })
    .catch((err) => console.error(err));
  var result = (
    await client.getEntries({ content_type: 'hflModule', limit: 1000 })
  ).items;
  console.log(result, 'Data');

  for (var i = 0; i < result.length; i++) {
    var module = {
      id: result[i].sys.id,
      module: result[i].fields.module,
      title: result[i].fields.title,
      image: result[i].fields.image,
      subtitle: result[i].fields.subtitle,
      overview: result[i].fields.overview,
      objective: result[i].fields.objective,
      direction: result[i].fields.direction,
      videos: result[i].fields.videos,
    };

    returnList.push(module);
  }
  returnList.sort((a, b) => a.module - b.module);
  return { modules: returnList, moduleDescription };
}

async function getLearningModuleById(moduleId) {
  const contentful = require('contentful');

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });

  return await client
    .getEntry(moduleId) // Replace with the ID of the entry you want to fetch
    .then((entry) => {
      var module = {
        id: entry.sys.id,
        module: entry.fields.module,
        title: entry.fields.title,
        image: entry.fields.image,
        subtitle: entry.fields.subtitle,
        overview: entry.fields.overview,
        objective: entry.fields.objective,
        direction: documentToHtmlString(entry.fields.direction),
        videos: entry.fields.media?.map((video) => {
          return {
            title: video.fields.title,
            description: video.fields.description,
            url: video.fields.url,
          };
        }),
      };

      return module;
    })
    .catch((error) => {
      console.error(error); // Handle any errors
      return { id: '-1', image: '' };
    });
}
async function getRecommendationDefinitionsByRoomName(roomName) {
  const contentful = require('contentful');
  var returnList = [];

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });

  var result = (
    await client.getEntries({
      content_type: 'hflRoomRecommendationDefinition',
      limit: 1000,
    })
  ).items;
  let rec;
  for (var i = 0; i < result.length; i++) {
    if (result[i].fields.roomTypeName.toLowerCase() == roomName.toLowerCase()) {
      rec = {
        name: result[i].fields.name,
        roomTypeName: result[i].fields.roomTypeName,
        category: result[i].fields.category,
        image: result[i].fields.image
          ? result[i].fields.image.fields.file.url
          : '',
      };
      returnList.push(rec);
    } else {
      if (
        result[i].fields.category === 'Assistive Tech' &&
        !returnList.find(
          (record) =>
            record.name.trim().toLowerCase() ===
            result[i].fields.name.trim().toLowerCase()
        )
      ) {
        const rec = {
          name: result[i].fields.name,
          roomTypeName: roomName,
          category: result[i].fields.category,
          image: result[i].fields.image
            ? result[i].fields.image.fields.file.url
            : '',
        };
        returnList.push(rec);
      }
    }
  }

  return returnList;
}

async function getClientConsiderations() {
  const contentful = require('contentful');
  const returnList = [];

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });

  const result = (
    await client.getEntries({
      content_type: 'hflSpecialConsideration',
      limit: 1000,
    })
  ).items;

  result.sort((a, b) => {
    const sortOrderA = a.fields.sortOrder || 0;
    const sortOrderB = b.fields.sortOrder || 0;
    return sortOrderA - sortOrderB;
  });

  for (let i = 0; i < result.length; i++) {
    returnList.push(result[i].fields.name);
  }

  return returnList;
}

async function getClientTenantTypes() {
  const contentful = require('contentful');
  const returnList = [];

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });

  const result = (
    await client.getEntries({
      content_type: 'hflClientTenantType',
      limit: 1000,
    })
  ).items;

  result.sort((a, b) => {
    const sortOrderA = a.fields.sortOrder || 0;
    const sortOrderB = b.fields.sortOrder || 0;
    return sortOrderA - sortOrderB;
  });

  for (let i = 0; i < result.length; i++) {
    returnList.push(result[i].fields.name);
  }

  return returnList;
}

async function getClientLivesWithTypes() {
  const contentful = require('contentful');
  const returnList = [];

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });

  const result = (
    await client.getEntries({
      content_type: 'hflClientLivesWithType',
      limit: 1000,
    })
  ).items;

  result.sort((a, b) => a.fields.sortOder - b.fields.sortOder);

  for (let i = 0; i < result.length; i++) {
    returnList.push(result[i].fields.name);
  }

  return returnList;
}

async function getClientHousingTypes() {
  const contentful = require('contentful');
  const returnList = [];

  const client = contentful.createClient({
    space: config.contentfulSpace,
    accessToken: config.contentfulAccessToken,
    environment: config.contentfulEnvironmentName,
  });

  const result = (
    await client.getEntries({
      content_type: 'hflClientHousingType',
      limit: 1000,
    })
  ).items;

  result.sort((a, b) => {
    const sortOrderA = a.fields.sortOrder || 0;
    const sortOrderB = b.fields.sortOrder || 0;
    return sortOrderA - sortOrderB;
  });

  for (let i = 0; i < result.length; i++) {
    returnList.push(result[i].fields.name);
  }

  return returnList;
}
